<template>
  <section>
    <div class="content-header">
      <h2>Works cited</h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <div class="accordion">
              <accordion
                headerText="Blog posts"
                accordionID="collapse01"
              >
                <ul>
                  <li class="pb-3">Hopkins, S. (2019, August 12). <em>Career Development is a Superpower</em>. Your Career Guide. <a href="https://www.yourcareerguide.ca/insights/career-development-is-a-superpower/" target="_blank">www.yourcareerguide.ca/insights/career-development-is-a-superpower/</a></li>
                  <li>CERIC. (2017, March 30). <em>CareerWise Blog</em>. CareerWise.  <br><a href="https://careerwise.ceric.ca/blog/#.Ya4sz9DMKM8" target="_blank">https://careerwise.ceric.ca/blog/#.Ya4sz9DMKM8</a></li>
                  </ul>
              </accordion>
              <accordion
                headerText="Journal articles"
                accordionID="collapse02"
              >
                <ul>
                  <li class="pb-3">Wada, K., McGroarty, E. J., Tomaro, J., & Amundsen-Dainow, E. (2019). Affirmative Career Counselling with Transgender and Gender Nonconforming Clients: A Social Justice Perspective. <em>Canadian Journal of Counselling and Psychotherapy</em>, 53(3), 255–275.<br> <a href="https://cjc-rcc.ucalgary.ca/article/view/61246/53346" target="_blank">cjc-rcc.ucalgary.ca/article/view/61246/53346</a></li>
                  <li class="pb-3">Redekopp, D. E., & Huston, M. (2018). The broader aims of career development: mental health, wellbeing and work. <em>British Journal of Guidance & Counselling, 47</em>(2), 246–257. <a href="https://www.tandfonline.com/doi/abs/10.1080/03069885.2018.1513451?journalCode=cbjg20" target="_blank">https://www.tandfonline.com/doi/abs/10.1080/03069885.2018.1513451?journalCode=cbjg20</a></li>
                  <li class="pb-3">Kirmayer, L. J., Dandeneau, S., Marshall, E., Phillips, M. K., & Williamson, K. J. (2011). Rethinking Resilience from Indigenous Perspectives. <em>The Canadian Journal of Psychiatry, 56</em>(2), 84–91. <a href="https://journals.sagepub.com/doi/10.1177/070674371105600203" target="_blank">https://journals.sagepub.com/doi/10.1177/070674371105600203</a></li>
                  <li class="pb-3">Andrew Litchfield, Jessica Frawley & Skye Nettleton (2010) Contextualising and integrating into the curriculum the learning and teaching of work‐ready professional graduate attributes, Higher Education Research & Development, 29:5, 519-534, <a href="https://www.tandfonline.com/doi/full/10.1080/07294360.2010.502220" target="_blank">https://www.tandfonline.com/doi/full/10.1080/07294360.2010.502220</a></li>
                  <li>Stewart, S.L., & Reeves, A. (2013). Intersections of Career Development and Post Secondary Education for Indigenous Students: Exploring the Integrity of Social and Cultural Issues. <a href="https://cjcd-rcdc.ceric.ca/index.php/cjcd/article/view/199" target="_blank">https://cjcd-rcdc.ceric.ca/index.php/cjcd/article/view/199</a></li>
                  </ul>
              </accordion>
              <accordion
                headerText="Magazine articles"
                accordionID="collapse03"
              >
                <ul>
                  <li>Valentine, G. (2018, May 30). How To Build A Resilient Career In A World Of Relentless Change. <em>Forbes</em>. <a href="https://www.forbes.com/sites/forbescoachescouncil/2018/05/30/how-to-build-a-resilient-career-in-a-world-of-relentless-change/?sh=2c2fcaa6358f" target="_blank">www.forbes.com/sites/forbescoachescouncil/2018/05/30/how-to-build-a-resilient-career-in-a-world-of-relentless-change/?sh=2c2fcaa6358f</a></li>
                  </ul>
              </accordion>
              <accordion
                headerText="Podcasts "
                accordionID="collapse04"
              >
                <ul>
                  <li class="pb-3">Royster, C., & Gagne, Y. (Hosts) (2021, June 8). I’m a post covid graduate. Now what? (20) [Audio podcast episode]. <em>In Hit the Ground Running</em>. Fast Company Magazine.<br> <a href="https://podcasts.apple.com/hu/podcast/im-a-post-covid-graduate-now-what/id1558760059?i=1000524621372" target="_blank">podcasts.apple.com/hu/podcast/im-a-post-covid-graduate-now-what/id1558760059?i=1000524621372</a></li>
                  <li>Malik, A. (Host) (2018, June 12). Decent Work in Your Workplace (Series 2: 6) [Audio podcast episode]. Lovers & Fighters. <em>What Millennial Workers Love Enough to Fight For</em>. Atkinson.<br><a href="https://justworkit.ca/podcast/lovers-fighters-ep-6-decent-work-in-your-workplace/" target="_blank">justworkit.ca/podcast/lovers-fighters-ep-6-decent-work-in-your-workplace/</a> </li>
                  </ul>
              </accordion>
              <accordion
                headerText="Videos"
                accordionID="collapse05"
              >
                <ul>
                  <li>Waterford Libraries. (2021, April 20).<br> <a href="https://www.youtube.com/watch?v=kM3V9e_qY7M&ab_channel=WaterfordLibraries" target="_blank"><em>Career Resilience with Waterford Libraries</em> </a> [Video]. YouTube.</li>
                  </ul>
              </accordion>
              <accordion
                headerText="Books"
                accordionID="collapse06"
              >
                <ul>
                  <li class="pb-3">Burnett, W., & Evans, D. J. (2016). <em>Designing your life: How to build a well-lived, joyful life</em>.</li>
                  <li class="pb-3">Carroll, M. (2012). <em>Fearless at Work: Timeless Teachings for Awakening Confidence, Resilience, and Creativity in the Face of Life's Demands</em>.</li>
                  <li class="pb-3">Duckworth, A. (2016). <em>Grit: The power of passion and perseverance</em>. </li>
                  <li class="pb-3">Dweck, C. S. (2006). <em>Mindset: The new psychology of success</em>. </li>
                  <li class="pb-3">Evans, D., Burnett, B., Evans, D. (2020). <em>Designing Your Work Life: How to Thrive and Change and Find Happiness at Work</em>.</li>
                  <li class="pb-3">Evans, D., Burnett, B., Evans, D. (2021). <em>Designing Your New Work Life: How to Thrive and Change and Find Happiness – and a New Freedom- at Work</em>.</li>
                  <li class="pb-3">Hanson, R., & Hanson, F. (2018). <em>Resilient: How to grow an unshakable core of calm, strength, and happiness</em>.</li>
                  <li class="pb-3">Harvard Business Review. (2021). <em>HBR’s 10 Must Reads on Career Resilience (with bonus article “Reawakening Your Passion for Work” By Richard E. Boyatzis, Annie McKee, and Daniel Goleman)</em>. Harvard Business Review Press.</li>
                  <li class="pb-3">Jackson, K. (2019). <em>Resilience at work: Practical tools for career success</em>.</li>
                  <li class="pb-3">Krumboltz, J.D. & Levin, A.S. (2010). <em>Luck Is No Accident: Making the Most of Happenstance in Your Life and Career (2<sup>nd</sup> Ed.)</em>  </li>
                  <li class="pb-3">Nagoski, E., & Nagoski, A. (2019). <em>Burnout: The secret to unlocking the stress cycle</em>.</li>
                  <li class="pb-3">Redekopp, D. E., & Huston, M. (2020). <em>Strengthening Mental Health Through Effective Career Development: A Practitioner's Guide</em>. </li>
                  <li class="pb-3">Reivich, K., & Shatté, A. (2003). <em>The resilience factor: 7 keys to finding your inner strength and overcoming life's hurdles</em>. New York: Three River Press.</li>
                  <li>Ungar, M. (2019). <em>Change Your World: The Science of Resilience and the True Path to Success</em>.</li>
                  </ul>
              </accordion>
              <accordion
                headerText="Online resources"
                accordionID="collapse07"
              >
                <ul>
                  <li class="pb-3">Niles, D. S., Amundson, D. N., & Joon Yoon, D. H. (n.d.). <em>About Us</em>. Hope-Action Group. Retrieved October 25, 2021, from <a href="https://hope-action.com/" target="_blank"></a></li>
                  <li class="pb-3"><em>About</em>. (2021). Advancing Career Development in Canada. Retrieved October 25, 2021, from <br><a href="https://ceric.ca/about/" target="_blank">ceric.ca/about/</a></li>
                  <li class="pb-3">The Trustees of the University of Pennsylvania. (2021). <em>Perma<sup>TM</sup> Theory of Well-Being and Perma<sup>TM</sup> Workshops</em>. Positive Psychology Center. Retrieved October 25, 2021, from <br> <a href="https://ppc.sas.upenn.edu/learn-more/perma-theory-well-being-and-perma-workshops " target="_blank">ppc.sas.upenn.edu/learn-more/perma-theory-well-being-and-perma-workshops </a></li>
                  <li class="pb-3">Martin, A., & Hughes, H. (2009). <em>How to Make the Most of Work-Integrated Learning: A Guide for Students, Lecturers & Supervisors</em>. Massey University Press.</li>
                  <li class="pb-3">Canadian Centre for Occupational Health and Safety. (2018, June 1). <em>Mental Health - Dealing with Stress in the Workplace</em>. CCOHS. Retrieved October 25, 2021, from  <a href="https://www.ccohs.ca/oshanswers/psychosocial/stress_workplace.html" target="_blank">www.ccohs.ca/oshanswers/psychosocial/stress_workplace.html</a></li>
                  <li class="pb-3">Canadian Mental Health Association, National. (n.d.). <em>Work-Life Balance Quiz</em>. Canadian Mental Health Association. Retrieved October 25, 2021, from <br><a href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/work-life-balance-quiz/#balance-quiz" target="_blank">cmha.ca/find-info/mental-health/check-in-on-your-mental-health/work-life-balance-quiz/#balance-quiz</a></li>
                  <li class="pb-3">Alberta Blue Cross. (2021). Resources: <em>Work-life balance</em>. Workplace Wellness Online. Retrieved October 25, 2021, from<br> <a href="https://www.workplacewellnessonline.ca/resources/work-life-balance.php" target="_blank">www.workplacewellnessonline.ca/resources/work-life-balance.php</a></li>
                  <li class="pb-3">Canadian Mental Health Association, National. (n.d.-a). <em>What’s Your Stress Index?</em> Canadian Mental Health Association. Retrieved October 25, 2021, from <br><a href="https://cmha.ca/find-info/mental-health/check-in-on-your-mental-health/whats-your-stress-index/" target="_blank">cmha.ca/find-info/mental-health/check-in-on-your-mental-health/whats-your-stress-index/</a></li>
                  <li class="pb-3">Canadian Centre for Occupational Health and Safety. (2–10-12-18). <em>Workplace Stress - General</em>. Retrieved October 25, 2021, from <br><a href="https://www.ccohs.ca/oshanswers/psychosocial/stress.html" target="_blank">www.ccohs.ca/oshanswers/psychosocial/stress.html</a></li>
                  <li class="pb-3">Canadian Centre for Occupational Health and Safety. (2021, October 25). <em>Health Promotion/Wellness/Psychosocial</em>. Retrieved October 25, 2021, from <br><a href="https://www.ccohs.ca/oshanswers/psychosocial/" target="_blank">www.ccohs.ca/oshanswers/psychosocial/</a></li>
                  <li><em>Career Resources for Diverse Groups</em>. (n.d.). Dalhousie University.  <br><a href="https://www.dal.ca/campus_life/career-and-leadership/job-resources-services/Resources/career-resources-for-diverse-groups.html" target="_blank">www.dal.ca/campus_life/career-and-leadership/job-resources-services/Resources/career-resources-for-diverse-groups.html</a></li>
                  </ul>
              </accordion>
              <accordion
                headerText="Documents"
                accordionID="collapse08"
              >
                <ul>
                  <li class="pb-3">Prince Edward Island Public Service Commission. (n.d.). Module One: The Changing Look of Careers. In <em>Career Bundles</em> (pp. 12–15). <a href="https://psc.gpei.ca/files/resiliency.pdf" target="_blank">https://psc.gpei.ca/files/resiliency.pdf</a></li>
                  <li>Centennial College: Centre for Global Citizenship, Education and Inclusion. (2021). <em>Roadmap to Career Readiness: Supporting the Employment of Students with Disabilities</em>.</li>
                  </ul>
              </accordion>
            </div>
    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '09',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
